import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import backgroundImage from './img/postb.jpg';
import './style.css';
import Post from './post/Post';
import PostDetail from './PostDetail'; // Import the PostDetail component

// ... (previous code)
// ... (previous code)

const RendetPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPost, setSelectedPost] = useState(null);
  const [postLimit, setPostLimit] = useState(9);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection('Posts')
          .orderBy('timeStamp', 'desc')
          .limit(postLimit)
          .get();

        const postList = snapshot.docs.map((doc) => ({
          id: doc.id, // Include the document ID as the id property
          ...doc.data(),
        }));
        
        if (postList.length < postLimit) {
          // If the fetched posts are less than the limit, there are no more posts to load
          setHasMorePosts(false);
        }

        // Set the posts to the fetched ones
        setPosts(postList);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [postLimit]);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const closePostDetail = () => {
    setSelectedPost(null);
  };

  const loadMorePosts = () => {
    if (hasMorePosts) {
      // Fetch all remaining posts without exceeding the total number of posts in the database
      setPostLimit((prevLimit) => prevLimit + (hasMorePosts ? Math.min(6, postLimit) : 0));
    }
  };
  const updatePost = (updatedPost) => {
    setSelectedPost(updatedPost);
    console.log('Updating post in parent component...');
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="whole-box">
            {posts.map((post, index) => (
              <div key={index} onClick={() => handlePostClick(post)}  className="post-container-limit">
                <Post
               
                  title={post.title}
                  content={post.content}
                  bgColor={['#e0d6c2', '#8f8868', '#976c54', '#b5a38c'][index % 4]}
                  image={post.imageURL}
              
                />
              </div>
            ))}
          </div>
          {hasMorePosts && (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
            <button className="load-more-button" onClick={loadMorePosts}>
              Load More
            </button>
            </div>
          )}
          <img src={backgroundImage} alt="Background" className="background-image" />
        </>
      )}

      {selectedPost && (
        <div className="post-detail-overlay">
          <div className="post-detail-container" style={{ background: '#e0d6c2' }}>
            {/* <PostDetail post={selectedPost}  />
             */}
                   <PostDetail post={selectedPost} updatePost={updatePost} closePostDetail={closePostDetail} />
            {/* <button className="button-close" onClick={closePostDetail}>
              Close
            </button> */}
             {/* <button className="close-button" onClick={closePostDetail}>×</button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default RendetPosts;
